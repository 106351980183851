<template>
  <div class="c-image">
    <div class="c-image__wrapper">
      <img v-if="image" :src="image.url" :alt="image.altText" class="c-image__image">
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue'

import { getAsset } from '@/services/assets'
import { addCloudinaryUrlParams } from '@/utils'

export default {
  name: 'Image',

  props: {
    data: Object
  },

  setup(props) {
    const image = ref(null)

    onBeforeMount(async () => {
      image.value = await getAsset(process.env.VUE_APP_COSS_NAMESPACE, props.data.content.assetId)
      image.value.url = addCloudinaryUrlParams(image.value.url, 'w_1200,c_scale')
    })

    return {
      image
    }
  }
}
</script>

<style lang="scss">
.c-image {
  display: block;
  padding: 1rem;
  margin: 1.375rem auto;

  @include bp(medium) {
    padding: 2.5rem 3.125rem;
  }
}

.c-image__wrapper {
  text-align: center;
}

.c-image__image {
  display: inline-block;
  width: auto;
  max-width: 100%;
}
</style>
